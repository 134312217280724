.Background {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: auto;
  width: auto;
  z-index: -1;
  background-color: var(--background);
  filter: blur(100px);
  -webkit-filter: blur(100px);
}

.Background * {
  position: absolute;
}

.Background svg {
  fill: var(--primary-light);
}

.Blob1 {
  top: 20%;
  left: -40%;
  scale: 600%;
}

.Blob2 {
  top: 40%;
  right: -20%;
  scale: 150%;

}

.Blob {
  animation: blob 60s ease-in-out infinite;
}

@keyframes blob {
  0% {
    transform: rotate(0deg) skew(0deg, 0deg);
  }

  50% {
    transform: rotate(30deg) skew(40deg, 20deg) translateX(50px);
  }

  100% {
    transform: rotate(0deg) skew(0deg, 0deg);
  }
}