.BaseButton {
  --bs-btn-border-color: var(--button-border-color);
  --bs-btn-hover-border-color: var(--button-border-color);
  --bs-btn-active-border-color: var(--button-border-color);

  --bs-btn-bg: var(--button-color);
  --bs-btn-active-bg: var(--button-color);
  --bs-btn-hover-bg: var(--button-color);

}

:root {
  --button-color: var(--root-button-color);
  --button-border-color: var(--button-color);
}