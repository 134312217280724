.TechCard {
  position: relative;
  height: 100px;
  width: 100px;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  color: var(--primary-text);
  transition: box-shadow 1s ease-in-out;
  background-color: rgba(115, 132, 192, 0.5);
  border: 0;
  box-shadow: 0px 0px 33px 3px white inset;
}

.TechCard::after {
  content: ' ';
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background-image: url('/src/img/crystal_bg.jpg');
  background-size: 300px;
  z-index: -1;
  background-position-x: inherit;
  border-radius: inherit;
  opacity: inherit;
  transition: inherit;
}

.TechCard * {
  margin: auto;
}

.TechCard svg {
  font-size: 28px;
  margin-top: 22px;
}

.Hidden {
  opacity: 0 !important;
}

.Highlight {
  box-shadow: 0px 0px 33px 3px white inset, 0px 0px 15px 10px var(--primary-dark);
}

.Transparent {
  opacity: 0.3;
}

@media only screen and (max-width: 1200px) {

  .TechCard {
    height: 75px;
    width: 75px;
    font-size: 0.8rem;
    padding: 0px;
  }

  .TechCard svg {
    font-size: 22px;
    margin-top: 14px;
  }
}