.Footer {
  width: 100%;
  height: 300px;
  background-color: var(--primary-dark);
  padding-top: 20px;
  margin-top: 100px;
}

.FooterText {
  color: #a9a9a9;
  margin: 6px 15px;
}