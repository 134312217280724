.LargeNavbar {
  position: fixed;
  top: 0px;
  height: 60px;
  width: 100%;
  padding: 10px;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, transparent 0%, var(--background) 10%);
  z-index: 10;
}

.Name {
  font-size: 1.5rem;
  font-weight: 600;
}

.middle-section {
  margin: auto;
  width: 1140px;
}

.middle-section>*,
.middle-section>*:hover {
  color: var(--primary-text);
  transition: text-shadow 1s;
}

.middle-section>*:not(.Name) {
  margin: 7px 12px;
  font-weight: 500;
}

.NavbarToggleButton {
  display: none;
  position: absolute;
  top: 14px;
  right: 6px;
}

@media only screen and (max-width: 1200px) {
  .Name {
    position: fixed;
    top: 0px;
    left: 4px;
  }

  .NavbarToggleButton {
    display: flex;
  }

  .LargeNavbar {
    right: 0px;
    text-align: end;
  }

  .LargeNavbar * {
    margin-right: 10px;
  }

  .middle-section {
    align-items: flex-end;
  }

  .middle-section>*:not(.Name) {
    transform: translateX(120%);
    transition: all 0.5s ease-in-out;
    min-width: 160px;
    text-align: left;
    border-radius: 8px;
    background: var(--background);
    background: radial-gradient(circle, var(--background) 0%, rgba(255, 255, 255, 0.676) 99%);
  }

  .middle-section>*:not(.Name) {
    margin: 7px 12px;
  }

  .LargeNavbar.NavbarOpen>.middle-section>* {
    transform: translateX(0);
    z-index: 10;
  }

  .LargeNavbar>.middle-section {
    flex-direction: column;
    margin-top: 40px;
    width: 0px;
  }
}