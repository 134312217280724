.ProjectCard {
  position: relative;
  max-width: 700px;
  border: 0;
  background-image: url('/src/img/white_crystal_bg.jpg');
  box-shadow: 0px 0px 33px 3px #fff inset, 0px 0px 33px 5px var(--primary-light);
}


.ProjectCard>.card-header {
  text-align: center;
}

.ProjectCard img {
  margin: 10px auto;
  border: solid #cecece 1px;
}

.ProjectCard a {
  text-decoration: none;
  cursor: pointer;
  color: var(--bs-card-cap-color);
}

.ProjectCard a:hover {
  text-decoration-line: underline;
  text-decoration-thickness: 3px;
}

.ProjectCard .card-header {
  background-color: transparent;
  border: 0;
}

.ProjectCard .card-footer {
  text-align: right;
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--primary-dark);
  background-color: transparent;
  border: 0;
}

.ProjectCard .card-footer>span {
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  .ProjectCard {
    max-width: 80%;
    font-size: 0.9rem;
  }
}