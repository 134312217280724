.DatGuiContainer {
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  z-index: 50;
}

.DatGuiContainer * {
  width: 100% !important;
}