.MainSection {
  width: 1140px;
  margin: auto;
  padding-top: 22px;
  display: flex;
  flex-wrap: wrap;
}

.InlineContactButton {
  display: inline-block;
  margin: auto 7px;
}

.InlineContactButton svg {
  margin: auto auto 4px 2px;
}

.Hero {
  width: 100%;
  display: flex;
}

.MainTitle {
  margin-top: 30vh;
  margin-bottom: 30vh;
}

.MainTitle h1 {
  font-size: 4.5rem;
}

.MainTitle h5 {
  font-weight: 400;
  line-height: 40px;
}

.CanvasContainer {
  position: relative;
  cursor: e-resize;
  padding: 0;
}

.Block {
  display: block;
  position: absolute;
  width: 100%;
  height: 200px;
  z-index: 2;
}

.Bottom {
  bottom: 0px;
  background: linear-gradient(0deg, var(--background), transparent);
}

.Bottom::after {
  content: ' ';
  position: absolute;
  top: 100%;
  width: 100%;
  height: 100px;
  background: linear-gradient(0deg, transparent, var(--background));
}

.Top {
  background: linear-gradient(0deg, transparent, var(--background));
}


@media only screen and (max-width: 1200px) {
  .CanvasContainer {
    position: absolute;
    z-index: 0;
  }

  .Hero,
  .CanvasContainer {
    top: 0px;
    left: 0px;
    right: 0px;
    height: 90vh;
  }

  .Hero {
    z-index: 5;
  }

  .MainTitle {
    position: relative;
    margin-top: 250px;
    margin-bottom: auto;
  }

  .MainTitle::after {
    content: ' ';
    position: absolute;
    opacity: 0.4;
    --padding: -10px;
    top: var(--padding);
    bottom: var(--padding);
    left: var(--padding);
    right: var(--padding);
    z-index: -1;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, var(--background) 25%, var(--background) 75%, rgba(255, 255, 255, 0) 100%);
  }

  .MainTitle h1 {
    font-size: 3rem;
  }

  .MainTitle h5 {
    font-weight: 400;
    line-height: 30px;
    font-size: 1.1rem;
  }
}