body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  font-family: 'Montserrat', sans-serif;
  color: var(--primary-text);
}

.TextShadow {
  text-shadow: 0 0 6px var(--primary-light);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --background: #fff;
  --primary-light: #748cb8;
  --root-button-color: #305caf;
  --primary-dark: #132647;
  --primary-text: #082643;
}