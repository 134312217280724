.ButtonContainer {
  min-height: 50px;
}

.ScrollButton {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 7;
  margin: auto auto;
}

.ScrollButton svg {
  margin: 0 3px 3px -1px;
}

.Bounce {
  animation: bounce 5s alternate-reverse infinite;
}

.GoUp {
  --suck-in-duration: 0.5s;
  --button-color: var(--primary-dark);
  --button-border-color: var(--background);
  position: fixed;
  bottom: 50px;
  right: 50px;

  animation: suckIn var(--suck-in-duration), relocate 1s var(--suck-in-duration);

}

.GoUp svg {
  transform: rotate(180deg);
  animation: rotate var(--suck-in-duration);
}

.OverFooter {
  --button-color: var(--primary-light);
  --button-border-color: var(--primary-dark);
  color: var(--primary-dark);
}

.OverFooter:hover {
  --button-color: var(--primary-dark);
  --button-border-color: var(--primary-light);
  color: var(--primary-light);
}


@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-30px);
  }
}

@keyframes rotate {

  0%,
  99% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes suckIn {
  0% {
    transform: translateX(50%);
    bottom: 90%;
    right: 50%;
    background-color: var(--root-button-color);
  }

  70% {
    transform: translateX(50%) scale(150%);
  }

  100% {
    transform: translateX(50%) scale(0%);
    bottom: 90%;
    right: 50%;
    background-color: var(--root-button-color);
  }
}

@keyframes relocate {
  0% {
    transform: scale(0%);
    bottom: 50px;
    right: 50px;
  }

  50% {
    transform: scale(150%);
  }

  75% {
    transform: scale(75%);
  }

  88% {
    transform: scale(112.5%);
  }

  94% {
    transform: scale(94%);
  }

  98% {
    transform: scale(102%);
  }

  99% {
    transform: scale(99%);
  }

  100% {
    transform: scale(100%);
  }
}